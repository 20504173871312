import NavMenuLayout from "component/layout/NavMenuLayout";
import FooterLayout from "component/layout/FooterLayout";
import PageRoute from "./PageRoute";

/**
 * 各个页面路由的注册信息，其中的层次结构仅为url的层级设计，与导航菜单的层次无关
 */
const routers: PageRoute[] = [
    {
        key: 'index',
        component: './pages/Home',
        title : '主页',
        path : '/',
        layout: {
            layout: NavMenuLayout,
            props: {
                outletStyle: false,
                breadcrumb : false,
                footer: false
            }
        }
    },
    {
        key: 'login',
        component: './pages/Login',
        title : '登录',
        path : '/login',
        auth: false,
    },
    {
        key: 'basic',
        title: "基础数据",
        path : '/basic',
        layout: NavMenuLayout,
        children: [
            {   
                key: 'efmRecords',
                component: './pages/EfmRecords',
                title : '报告管理',
                path: "efm_record"
            },
            {   
                key: 'preEfmRecords',
                component: './pages/PreEfmRecords',
                title : '预选材管理',
                path: "pre_efm_record"
            },
            {   
                key: 'EfmSegments',
                component: './pages/EfmSegments',
                title : '案例素材管理',
                path: "efm_segments"
            },
            {   
                key: 'caseRecords',
                component: './pages/CaseRecords',
                title : '病例管理',
                path: "case_record"
            },
        ]
    },
    {
        key: 'data',
        title: "标注与分析",
        path : '/data',
        layout: NavMenuLayout,
        children: [
            {
                key: 'efmTagging',
                component: './pages/EfmTagging',
                title: '标注与批注',
                path: 'tagging'
            },
            {
                key: 'labels',
                component: './pages/Labels',
                title: '标注统计',
                path: 'labels'
            },
            {
                key: 'comments',
                component: './pages/EfmComments',
                title: '批注统计',
                path: 'comments'
            },
            {
                key: 'efm_detail',
                component: './pages/EfmDetail',
                title: 'EFM详情',//EfmDetail
                path: 'efm_detail'
            },
            {
                key: 'efm_view',
                component: './pages/EfmTagging',
                title: 'EFM标注',//EfmDetail
                path: 'efm'
            },
            {
                key: 'audit',
                component: './pages/Audit',
                title: 'EFM审核',
                path: 'audit'
            },
            {
                key: 'takePhotos',
                component: './pages/TakePhotos',
                title: '随手拍管理',
                path: 'takePhotos'
            }
        ]
    },
    {
        key: 'courseware',
        title: '课程',
        path: '/course',
        layout: NavMenuLayout,
        children: [
            {
                key: 'EditCourse',
                component: './pages/EditCourse',
                title: "制作课程",
                path: 'edit'
            },
            {
                key: 'Courses',
                component: './pages/Courses',
                title: "课程列表",
                path: 'list'
            }
        ]
    },
    {
        key: 'courseware',
        title: '课件',
        path: '/courseware',
        layout: NavMenuLayout,
        children: [
            {
                key: 'courseware',
                component: './pages/Courseware',
                title: "课件",
                path: ''
            },
            {
                key: 'EditCourseware',
                component: './pages/EditCourseware',
                title: "制作课件",
                path: 'edit'
            },
            {
                key: 'StudentCourseware',
                component: './pages/StudentCourseware',
                title: "学习课件",
                path: 'student'
            }
        ]
    },
    {
        key: 'studyManage',
        title: "教学计划",
        path : '/studyManage',
        layout: NavMenuLayout,
        children: [
            {
                key: 'studyPlans',
                component: './pages/StudyPlans',
                title: '教学计划管理',
                path: 'studyPlans'
            },
            {
                key: 'studyPlanInfo',
                component: './pages/StudyPlanInfo',
                title: '教学计划详情',
                path: 'studyPlanInfo'
            }
        ]
    },
    {
        key: 'examination',
        path: "/examination",
        layout: NavMenuLayout,
        title: '考试管理',
        children: [
            {
                key: 'examinationManage',
                component: './pages/ExaminationsManage',
                title: '考试题管理',
                path: 'list'
            },
            {
                key: 'editExamination',
                component: './pages/EditExamination',
                title: '编辑考试题',
                path: 'edit'
            },
        ]
    },
    {
        key: 'myStudyManage',
        title: "我的学习计划",
        path : '/myStudyManage',
        layout: NavMenuLayout,
        children: [
            {
                key: 'myStudyPlans',
                component: './pages/MyStudyPlans',
                title: '我的学习计划',
                path: 'myStudyPlans'
            },
            {
                key: 'myStudyPlanInfo',
                component: './pages/MyStudyPlanInfo',
                title: '学习计划详情',
                path: 'myStudyPlanInfo'
            },
            {
                key: 'MyExaminationQuestions',
                component: './pages/MyExaminationQuestions',
                title: '考试',
                path: 'myExaminationQuestions'
            }
        ]
    },
    {
        key: 'settings',
        title: "系统管理",
        path: "/settings",
        layout: NavMenuLayout,
        children: [
            {
                key: 'users',
                component: './pages/Users',
                title: '用户管理',
                path: 'users'
            },
            {
                key: 'roles',
                component: './pages/Roles',
                title: '角色管理',
                path: 'roles'
            },
            {
                key: 'popedoms',
                component: './pages/Popedoms',
                title: '权限管理',
                path: 'popedoms'
            },
            {
                key: 'labelDefine',
                component: './pages/LabelDefines',
                title: '标注设置',
                path: 'label_define'
            },
            {
                key: 'importLogger',
                component: './pages/ImportRecords',
                title: 'EFM导入记录',
                path: 'import_logger'
            }
        ]
    }, 
    {
        key: 'mobile',
        title: '', 
        path: '/mobile',
        layout: FooterLayout,
        children: [
            {
                key: 'mobileLogin',
                component: './pages/MobileLogin',
                title: '登录',
                path: 'login',
                auth: false
            },
            {
                key: 'mobileAuth',
                component: './pages/MobileAuth',
                title: '认证',
                path: 'auth'
            },
            {
                key: 'mobileRegister',
                component: './pages/MobileRegister',
                title: '注册',
                path: 'register'
            },
            {
                key: 'mobileStudyPlan',
                component: './pages/MobileStudyPlan',
                title: '加入学习计划',
                path: 'studyPlan'
            },
            {
                key: 'mobileSubmitPhoto',
                component: './pages/MobileSubmitPhoto',
                title: '随手拍',
                path: 'submit_photo'
            },
            {
                key: 'takePhotoHistory',
                component : "./pages/TakePhotoHistory",
                title: '随手拍历史记录',
                path: 'take_photo_history'
            }
        ]
    }
];


const map: Map<string, { title: string, path: string, auth: boolean }> = new Map();
const parentPath: Array<string> = [];

const readChildrenRoutes = (pages: PageRoute[]) => {
    pages.forEach(p => {
        if (p.children && p.children.length) {
            parentPath.push(p.path);
            readChildrenRoutes(p.children);
        } else {
            let path = parentPath.join('/') + '/' + p.path;
            path = path.replaceAll(/\/+/g, '/');
            map.set(p.key, {path, title: p.title, auth: p.auth !== false});
        }
    })
    if (parentPath.length) {
        parentPath.pop();
    }
}
readChildrenRoutes(routers);

const getPage: (routeKey: string) => { title: string, path: string, auth: boolean } = routeKey => {
    const page = map.get(routeKey);
    if (page) {
        return page;
    } else {
        console.log("connot found route for key '" + routeKey + "', fallback to /")
        return {path: "/", title: "主页", auth: true};
    }

}


const getAllPages: () => { title: string, path: string, auth: boolean }[] = () => {
    return Array.from(map.values());
}

export {routers, getPage, getAllPages};