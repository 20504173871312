import { Avatar, Breadcrumb, Button, Dropdown, Layout, Menu, MenuProps, Spin} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router";
import logo from "../../assets/logo.png";
import style from "./NavMenuLayout.module.css";
import React, { FC, useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import useGlobalData from "../../hooks/useGlobalData";
import FooterText from "./FooterText";
import useHttpService from '../../hooks/useHttpService';

const {Content, Header, Footer} = Layout;

type MenuItem = {key:string, label: string};
type NavMenuLayoutProp = {outletStyle : boolean | undefined, breadcrumb: boolean| undefined, footer: boolean| undefined}

const NavMenuLayout : FC<NavMenuLayoutProp>= ({outletStyle, breadcrumb, footer}) => {
    const navigate = useNavigate();
    const globalData = useGlobalData();
    const location = useLocation();
    const { http, loading } = useHttpService();
    
    const state = useLocalObservable(()=>({
        collapsed: false,
        selectedItems: [] as MenuItem[],
        // 静态引入会有循环依赖问题，这里改为动态引入
        items: (require("routers/navigations") as any).default as MenuProps['items'],
        get selectedKeys(){
            return this.selectedItems.map((item:MenuItem) => item.key);
        },
        get menuItems() :MenuProps['items']{
            const list = this.items as MenuProps['items'];
            const user = globalData.user;
            const {popedoms=[]} = user || {};
            const popedomKeys = popedoms && popedoms.map(item => item.value);
            const filterList =  this.items.map(item => {
                const {key,children} = item;
                // console.log("===============",key,popedomKeys,popedomKeys.includes(key));
                if(popedomKeys && popedomKeys.includes(key)){
                    if(children){
                    const newChildren = [];
                    children && children.map(child => {
                        //console.log("子列表",child.key,popedomKeys)
                        if(popedomKeys.includes(child.key)){
                            newChildren.push(child);
                        }
                    })
                    item.children = newChildren;
                }
                    return item;
                }
            })
            // 这里的list就是导航菜单项目, 在这里过滤
            return filterList;

        },
        onCollapse(collapsed:boolean){
            this.collapsed = collapsed
        },
        onSelectMenu({item, key}) {
            navigate(item.props.path);
        }, 
        logout() {
            http.post("/account/logout");
            navigate("/login");
        },
        // 根据当前页面的pathname，分析所对应的导航菜单的路径，并将其激活(高亮)
        updateSelectKeys(location) {
            const keys:MenuItem[] = [];
            const find = (list:any[]) => {
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    if (item.children && item.children.length) {
                        keys.push({key: item.key, label: item.label});
                        const found = find(item.children);
                        if (found) {
                            return true;
                        }
                    } else if (item.path === location.pathname){
                        keys.push({key: item.key, label: item.label});
                        return true;
                    } 
                    else if(i === list.length - 1){
                        keys.pop();
                    }
                }
                return false;
            }
            
            find(this.items);
            this.selectedItems = keys;
        }
    }));


    const userMenu: MenuProps['items'] = [{key: 'logout', label:<Button type="link" onClick={state.logout}>退出</Button>}];

    useEffect(() => {
        state.updateSelectKeys(location);
    }, [location, state])

    return (
        <Layout className={style.layout}>
            <Header className={style.head}>
                <div className={style.headLeft}>
                    <div className={style.logo}>
                        {//<img src={logo} alt="logo"/>
                        }
                        <span className={style.title}>
                            <span className={style.logoTxt}>
                                <span style={{color: '#1a4698'}}>C</span><span style={{color: '#868686'}}>T</span><span style={{color: '#3a3a3a'}}>G</span>
                            </span>
                            <span>数据标记管理</span>
                        </span>
                    </div>
                    {globalData.user && <Menu theme="dark" mode="horizontal" onSelect={state.onSelectMenu} selectedKeys={state.selectedKeys} items={state.menuItems} />}
                </div>
                {globalData.user ? 
                <div className={style.headRight}>
                    <Avatar src={globalData.user?.avatar} />
                    <Dropdown menu={{items: userMenu}}>
                        <div className={style.userInfo}>
                            <div className={style.username}>{globalData.user?.name}</div>
                        </div>
                    </Dropdown>
                </div> :
                <div className={style.headRight}><Spin /></div>}
            </Header>
            <Content className={outletStyle === false ? null: style.content } >
                {breadcrumb !== false && <Breadcrumb style={{ margin: '16px 0' }} items={state.selectedItems.map(item => ({key: item.key, title: item.label}))} />}
                <div className={outletStyle === false ? null : style.outlet}><Outlet /></div>
            </Content>
            {footer !== false && <Footer className={style.footer} ><FooterText /></Footer>}
        </Layout>
    )
}

export default observer(NavMenuLayout);