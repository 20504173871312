import axios, { AxiosRequestConfig, Canceler } from "axios";
import {message} from "antd";
import { useLocation, useNavigate } from "react-router";
import { useLocalObservable } from "mobx-react-lite";
import { isWechat } from "helper/functions";
import QueryString from "qs";

const promiseMap = new Map<string, Promise<any>>();

type HttpService = {
    http: {
        get: (url: string, data?: object | undefined) => Promise<any>;
        post: (url: string, data?: object | undefined) => Promise<any>;
        download: (url: string, data?: object | undefined) => Promise<any>;
    };
    loading: boolean;
    cancel: Canceler;
}


function useHttpService(errorMessageDuration: number = 3):HttpService {
    const loadingState = useLocalObservable(()=>({
        value: false,
        set loading(v: boolean) {
            this.value =v;
        },
        get loading():boolean {
            return this.value;
        }
    }))
    const navigate = useNavigate();
    const location = useLocation();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const redrectToLogin = ()=>{
        const loginPage = isWechat() ? '/mobile/login' : '/login';
        if (location.pathname !== loginPage) {
            console.log('401未登录,重定向...')
            const {auth_token, ...query} = QueryString.parse(location.search.replace("?", "")) || {};
            let redirect = location.pathname;
            if (query) {
                redirect = redirect + '?'+ QueryString.stringify(query);
            }
            if (location.hash) {
                redirect = redirect + '#' + redirect;
            }
            redirect = encodeURIComponent(redirect)
            
            let loginUrl = `${loginPage}?redirect=${redirect}`;
            if(auth_token) {
                loginUrl = loginUrl + '&auth_token=' + auth_token;
            }
            navigate(loginUrl);
        }
    }

    const httpService = (httpMethod: 'get' | 'post'| 'download') => (url: string, data?: object) => {

        let requestKey = httpMethod+'|'+url;
        if (data) {
            requestKey = requestKey + '|' + JSON.stringify(data);
        }
        let promise = promiseMap.get(requestKey)
        //合并并发的相同请求
        if (promise) {
            return promise;
        }

        promise = new Promise<any>((resolve, reject) => {
            const tokenValue =  localStorage.getItem('token');
            loadingState.loading = true;
            const method = httpMethod === 'download' ? 'get' : httpMethod;
            let requestUrl = '/api' + url;
            if (url.startsWith("http://") || url.startsWith("https://")) {
                requestUrl = url;
            }
            const config: AxiosRequestConfig = {
                method,
                headers: {'X-Token': tokenValue || ''},
                url: requestUrl,
                params:( httpMethod === 'get' || httpMethod === 'download') ? data : null,
                data: data,
                cancelToken: source.token,
                paramsSerializer: {
                    serialize: params => QueryString.stringify(params, {arrayFormat: 'repeat'})
                }
            };
            if (httpMethod === 'download') {
                config.responseType = 'blob';
            }
            axios(config).then(resp => {
                loadingState.loading = false;
                // 需要下载
                const contentDisposition = resp.headers['content-disposition'];
                if (contentDisposition && contentDisposition.startsWith('attachment;')) {
                    let blob = new Blob([resp.data], { type: resp.headers['content-type'] })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    let fileName = (contentDisposition.match(/filename="(.+)"/) || ['', 'file'])[1];
                    fileName = decodeURI(fileName);
                    link.download = fileName;
                    link.click();
                    resolve(fileName);
                } else {
                    resolve(resp.data);
                    
                }
            })
            .catch(error => {
                loadingState.loading = false;
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                } else if (error.response) {
                    const status = error.response.status;
                    if (status === 401) {
                        redrectToLogin();
                    }
                    else if (status === 403) {
                        errorMessageDuration && message.error("您没有此权限", errorMessageDuration);
                    }
                    else if (error.response.data) {
                        errorMessageDuration && message.error(error.response.data.error, errorMessageDuration);
                    }
                } else if (error.message === 'Network Error') {
                    errorMessageDuration && message.error("网络错误,请检查您的网络.", errorMessageDuration);
                } else {
                    console.error(error.response)
                    errorMessageDuration && error.message && message.error(error.message, errorMessageDuration);
                }
                reject(error);
                
            }).finally(()=>{
                promiseMap.delete(requestKey);
            });
        });
        promiseMap.set(requestKey, promise);
        return promise;
    }

    const http = {get: httpService('get'), post: httpService('post'), download: httpService('download')}

    return {http, loading : loadingState.loading, cancel: source.cancel};
}

export type { HttpService };
export default useHttpService;



